import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import colors from "styles/colors"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import ExternalCard from "components/ExternalCard"

const CodeTitle = styled("h1")`
  margin-bottom: 1em;
`

const SectionTitle = styled("h2")`
  margin: 3em auto 1em auto;
`

const CodeGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5em;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
    grid-gap: 2.5em;
  }
`

const CodeAction = styled(Link)`
  grid-column: 3;
  font-weight: 600;
  text-decoration: none;
  color: currentColor;
  transition: all 150ms ease-in-out;
  margin-left: auto;
  margin-top: 0.5em;

  @media (max-width: 1050px) {
    grid-column: 1;
    margin-left: 0;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column: 1;
    margin-left: 0;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
    margin-left: auto;
  }

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
  }

  &:hover {
    color: ${colors.blue500};
    transition: all 150ms ease-in-out;

    span {
      transform: translateX(0px);
      opacity: 1;
      transition: transform 150ms ease-in-out;
    }
  }
`

const Code = ({ posts, projects, externals, meta }) => (
  <>
    <Helmet
      title={`Code | Carolyn Stransky`}
      htmlAttributes={{ lang: "en" }}
      link={[
        {
          rel: `canonical`,
          href: `https://workwithcarolyn.com/code`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: `Selected coding projects, technical blog posts and tutorials.`,
        },
        {
          property: `og:title`,
          content: `Code | Carolyn Stransky`,
        },
        {
          property: `og:description`,
          content: `Selected coding projects, technical blog posts and tutorials.`,
        },
        {
          name: `og:image`,
          content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: `Code | Carolyn Stransky`,
        },
        {
          name: `twitter:description`,
          content: `Selected coding projects, technical blog posts and tutorials.`,
        },
        {
          name: `twitter:image`,
          content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
        },
      ].concat(meta)}
    />
    <Layout>
      <CodeTitle>Code</CodeTitle>
      <SectionTitle>Open-source projects</SectionTitle>
      <CodeGrid>
        <ExternalCard
          title={projects[0].node.project_title}
          description={projects[0].node.project_preview_description}
          link_text={projects[0].node.project_link_text}
          link={projects[0].node.project_link}
          uid={projects[0].node._meta.uid}
        />
        <ExternalCard
          title={projects[1].node.project_title}
          description={projects[1].node.project_preview_description}
          link_text={projects[1].node.project_link_text}
          link={projects[1].node.project_link}
          uid={projects[1].node._meta.uid}
        />
        <ExternalCard
          title={projects[2].node.project_title}
          description={projects[2].node.project_preview_description}
          link_text={projects[2].node.project_link_text}
          link={projects[2].node.project_link}
          uid={projects[2].node._meta.uid}
        />
        <CodeAction to={"/projects"}>
          More projects <span>&#8594;</span>
        </CodeAction>
      </CodeGrid>
      <SectionTitle>Selected technical writing</SectionTitle>
      <CodeGrid>
        <ExternalCard
          category={externals[0].node.external_category}
          link_text={externals[0].node.external_link_text}
          title={externals[0].node.external_title}
          date={externals[0].node.external_date}
          description={externals[0].node.external_preview_description}
          link={externals[0].node.external_link}
          uid={externals[0].node._meta.uid}
        />
        <ExternalCard
          category={externals[1].node.external_category}
          link_text={externals[1].node.external_link_text}
          title={externals[1].node.external_title}
          date={externals[1].node.external_date}
          description={externals[1].node.external_preview_description}
          link={externals[1].node.external_link}
          uid={externals[1].node._meta.uid}
        />
        <ExternalCard
          category={externals[2].node.external_category}
          link_text={externals[2].node.external_link_text}
          title={externals[2].node.external_title}
          date={externals[2].node.external_date}
          description={externals[2].node.external_preview_description}
          link={externals[2].node.external_link}
          uid={externals[2].node._meta.uid}
        />
        <CodeAction to={"/technical-writing"}>
          More writing <span>&#8594;</span>
        </CodeAction>
      </CodeGrid>
    </Layout>
  </>
)

export default ({ data }) => {
  const posts = data.prismic.allPosts.edges
  const projects = data.prismic.allProjects.edges
  const externals = data.prismic.allExternals.edges
  const meta = data.site.siteMetadata
  if (!posts) return null

  return (
    <Code posts={posts} projects={projects} externals={externals} meta={meta} />
  )
}

Code.propTypes = {
  posts: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  externals: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allPosts(sortBy: post_date_DESC) {
        edges {
          node {
            post_title
            post_date
            post_category
            post_link_text
            post_preview_description
            post_author
            post_pdf_link
            additional_pdf_link
            additional_pdf_button_text
            post_external_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            _meta {
              uid
            }
          }
        }
      }
      allProjects(sortBy: project_date_DESC) {
        edges {
          node {
            project_title
            project_date
            project_category
            project_link_text
            project_preview_description
            project_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            _meta {
              uid
            }
          }
        }
      }
      allExternals(sortBy: external_date_DESC) {
        edges {
          node {
            external_title
            external_date
            external_category
            external_link_text
            external_preview_description
            external_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
