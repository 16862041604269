import React from "react";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import PropTypes from "prop-types";
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ExternalCardContainer = styled(OutboundLink)`
    border: 1px solid ${colors.grey200};
    padding: 3em 2.5em 2.25em 2.5em;
    border-radius: 3px;
    text-decoration: none;
    color: currentColor;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    transition: all 150ms ease-in-out;

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;
        cursor: pointer;

        .ExternalCardAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }
    }
`

const ExternalCategory = styled("h6")`
    font-weight: 600;
    color: ${colors.grey600};
`

const ExternalTitle = styled("h3")`
    margin: 0;
    margin-top: 0.5em;
`

const ExternalDescription = styled("div")`
    margin-top: 2em;
    margin-bottom: 4em;

    p:last-of-type {
        margin: 0;
    }
`

const ExternalCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }
`

const ExternalCard = ({ category, link, link_text, title, description}) => {
    return(
    <ExternalCardContainer className="CodeExternalCard" href={link.url} target="_blank" rel="noopener noreferrer">
        {category && <ExternalCategory>
            {category[0].text}
        </ExternalCategory>}
        <ExternalTitle>
            {title[0].text}
        </ExternalTitle>
        <ExternalDescription>
            {RichText.render(description)}
        </ExternalDescription>
        <ExternalCardAction className="ExternalCardAction">
            {link_text[0].text} <span>&#8594;</span>
        </ExternalCardAction>
    </ExternalCardContainer>
)
}

export default ExternalCard;

ExternalCard.propTypes = {
    category: PropTypes.array,
    date: PropTypes.string,
    title: PropTypes.array.isRequired,
    description: PropTypes.array,
    link_text: PropTypes.array.isRequired,
    link: PropTypes.object.isRequired,
    uid: PropTypes.string
}